const Actions = {
  CLOSE_MODAL: 'CLOSE_MODAL',
  OPEN_MODAL: 'OPEN_MODAL',
}

const ModalNames = {
  AUTH_EMAIL: 'AUTH_EMAIL',
  AUTH_PASSWORD: 'AUTH_PASSWORD',
  AUTH_PASSWORD_RESET: 'AUTH_PASSWORD_RESET',
  AUTH_SIGNUP: 'AUTH_SIGNUP',
  SETTINGS: 'SETTINGS',
  VERIFY_EMAIL: 'VERIFY_EMAIL',
  CONFIRM_DELETE_FOLLOW_UP: 'CONFIRM_DELETE_FOLLOW_UP',
}

export {
  ModalNames,
}

export default Actions
